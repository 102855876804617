<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popup-component="popupComponent"
        :show-add-button="false"
        title="User List"
        icon="mdi-account-multiple"
        stateendpoint="users"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButtons"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import UserPopup from "../components/popups/UserPopup";
import SitePopup from "../components/popups/SitePopup";

export default {
  name: 'Users',
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Dashboard - User List | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, UserPopup},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'email',
          value: 'email'
        },
        {
          text: 'Total Credit',
          align: 'right',
          value: 'totalCredit'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'name'},
        {value: 'email'},
        {value: 'totalCredit'},
        {value: 'isActive'},
        {value: 'action'}
      ],
      actions: {
        load: 'loadUsers',
        updateStatus: 'updateStatus',
        update: 'updateEmail'
      },
      actionButtons: [
        {
          category: "update",
          icon: "mdi-mail",
          color: "success",
          text: "Change email",
          clickHandler: "editItem"
        }
      ]
    }
  },
  computed: {
    popupComponent() {
      return UserPopup;
    }
  },
}
</script>
